<template>
  <div class="page">
    <!-- <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>当前位置</el-breadcrumb-item>
        <el-breadcrumb-item>服务</el-breadcrumb-item>
        <el-breadcrumb-item>{{ info.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="intro">

      <el-row>
        <el-col :span="12">
          <div>
            <el-image v-if="info.img" style="width: 380px; height: 230px;" :src="info.img" class="img" fit="cover" />
          </div>
        </el-col>
        <el-col :span="12">
          <h1 class="name">{{ info.name }}</h1>
          <p>
            <span class="price">¥{{ info.current_price }}</span>
            <span class="old_price">¥{{ info.original_price }}</span>
          </p>
          <div class="buy" @click="show = true">立即购买</div>
        </el-col>
      </el-row>
    </div>

    <div class="se-content" v-html="info.desc" />

    <el-dialog :visible.sync="show" title="请输入您的个人信息" width="30%">
      <el-input v-model="mobile" placeholder="请输入有效的手机号码" />
      <el-input v-model="user_name" placeholder="请输入收货人姓名" style="margin-top:10px" />
      <el-input v-model="address" placeholder="请输入收货地址" style="margin-top:10px" />
      <!-- <el-select v-model="coupon_id" placeholder="优惠卷" style="margin-top:10px">
        <el-option v-for="item in couponList" :key="item.id" :label="item.coupon_money" :value="item.id">
        </el-option>
      </el-select> -->
      <p class="pay-title">选择支付类型</p>
      <div class="pay-box">
        <div @click="pay_type = 1">
          <p>
            <i class="iconfont icon-zhifubao" style="color: #18ADE7" />
          </p>
          <el-radio v-model="pay_type" :label="1">支付宝</el-radio>
        </div>
        <div @click="pay_type = 2">
          <p>
            <i class="iconfont icon-weixin" style="color: #10CD19" />
          </p>

          <el-radio v-model="pay_type" :label="2">微信</el-radio>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <span class="pay-btn" @click="pay">确认支付</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "goodsInfo",
  data() {
    return {
      show: false,
      mobile: "",
      user_name: "",
      address: "",
      pay_type: 1,
      info: {},
      // coupon_id: "",
      // couponList: [],
    };
  },
  mounted() {
    this.$fecth
      .post("goods/getGoodsInfo", {
        id: this.$route.query.id,
      })
      .then((res) => {
        this.info = res;
      });
    // this.getCoupon();
  },
  methods: {
    // getCoupon() {
    //   let data = {
    //     id: this.$route.query.id,
    //   };
    //   this.$fecth.post("coupon/getList", data).then((res) => {
    //     this.couponList = res;
    //   });
    // },
    pay() {
      if (this.mobile == "") {
        this.$message.error("请输入有效的手机号码");
        return;
      }
      if (this.pay_type == 1) {
        var payurl =
          "https://bkgaoshou.com/index.php/api/order/getOrder?goods_id=" +
          this.info.id +
          "&mobile=" +
          this.mobile +
          "&address=" +
          this.address +
          "&type=" +
          this.pay_type +
          "&user_name=" +
          this.user_name +
          "&user_id=" +
          this.$store.state.userInfo.id 
          // +
          // "&coupon_id=" +
          // this.coupon_id
          ;
        window.open(payurl);
      } else {
        this.$message.error("暂未开通微信支付");
      }
    },
  },
};
</script>
<style lang="less">
.se-content {
  width: 1200px;

  img {
    width: 100%;
  }
}
</style>
<style lang="less" scoped>
.page {

  .img {
    width: 100%;
  }

  .intro {
    color: white;
    background: #4870f6;
    padding: 120px 160px;

    .name {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }

  .buy {
    margin: 20px auto;
    width: 278px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 24px;
    color: #ffffff;
    border-radius: 1px;
    background-image: linear-gradient(to right, #ff971a, #ff971a);
  }

  .price {
    font-size: 26px;
    color: red;
    font-weight: bold;
  }

  .old_price {
    text-decoration: line-through;
    font-size: 18px;
    color: #8c939d;
    margin-left: 10px;
  }

  .text-1 {
    font-size: 40px;
    font-weight: bold;
  }

  .text-2 {
    color: #8c939d;
  }

  .bot {
    text-align: center;
    background-image: linear-gradient(to right, #f59392, #f8c399);
    color: #ffffff;
    padding: 40px 0;
    margin-bottom: 20px;

    .t1 {
      margin-bottom: 5px;
      font-size: 40px;
    }

    .t2,
    .t3 {
      margin-bottom: 5px;
      font-size: 26px;
    }
  }

  .pay-title {
    margin: 10px 0;
  }

  .pay-box {
    text-align: center;

    >div:first-child {
      margin-right: 30px;
    }

    >div {
      display: inline-block;
      width: 100px;

      .iconfont {
        font-size: 80px;
      }
    }
  }

  .dialog-footer {
    text-align: center;

    .pay-btn {
      background-image: linear-gradient(to right, #f59392, #f8c399);
      color: #ffffff;
      display: inline-block;
      width: 180px;
      height: 60px;
      line-height: 60px;
      font-size: 22px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
</style>